import React from 'react';
import PropTypes from 'prop-types';
import { CategoryStatementStyle, CategoryStatementSubheader } from './statement.css';

const CategoryStatement = ({ children, category, subheader, as = '', size, pic }) => {
    return (
        <CategoryStatementStyle pic={pic} >
            {category}
            <CategoryStatementSubheader>
                {subheader}
            </CategoryStatementSubheader>
        </CategoryStatementStyle>
    );
};

let textGenerator = (text) => {
    let textArray = text.split(" ");
    let firstHalf = textArray.slice(0, Math.floor(textArray.length / 2));
    let secondHalf = textArray.slice(Math.floor(textArray.length / 2), textArray.length)
    return (
        `${firstHalf} + <br/> + ${secondHalf}`
    )
}

CategoryStatement.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default CategoryStatement;