import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CTAStatement from '../components/Statement/ctaStatement'
import CategoryStatement from '../components/Statement/categoryStatement'
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'
import PillarSection from '../components/Section/pillarSection'

const Pillars = ({ data }) => {
  console.log(data)
  const subheader = "equity. future. innovation. prosperity."

  const SEO = {
    pageInfo: "Ntheos Mag | Pillars"
  }
  const pillars = [
    " ",
    " ",
    " ",
    " ",
    " ",
    " "
  ]
  return (
    <Layout seo={SEO}>
      <CategoryStatement category="pillars" subheader={subheader} />
      <br />

      <Section type="pillar" section='ONE' size='half'>
        <PillarSection title="Equity first." body="The world is full of equity 
        that is not distributed equitably. We (politely) think that is bullshit. 
        Ntheos is a digital society that invests in it's community while 
        simultaneuosly allowing for it's community to invest in one another.
         We believe that widespread creativity infused with distributed capital 
         allocation and the many benefits crypto/blockchain provides will greatly impact
         the world. Barriers are being reduced to participate in the world economy,
         middlemen are being removed and trsutworthy transparency now exists in the
         era of Web 3.0. This will increase the pie exponentially and create a positive sum
         game for more players. The more people that 
         have a stake, the better. We all eat.."/>
      </Section>
      <Section type="pillar" section='TWO' size='half'>

        <PillarSection title="Embrace the Future" body="Every once in a while, a new technology 
        comes into the world and slowly creates change that will trickle into nearly every 
        part of the world. These disruptive changes require 
        us to learn new things, change our habits and leave our comfortable ways behind. 
        This change can be intimidating but we are better off being apart of it rather than 
        fighting against it. We either ride the wave, create the wave
         or get swept by the wave. It's our choice and at Ntheos we choose to create it." />
      </Section>
      <Section type="pillar" section='THREE' size='half'>
        <PillarSection title="Innovation Through Creation" body="At Ntheos, we
         believe that innovation and creation are attached to one another. Without 
         encourgaed creation, we lose innovation. Currently, creators across the board are
         disincentivized to create economically. Gig work has replaced assembly lines and while it
        offers flexibility, it still stifles creativity. We believe that our society will
        be better across the board if more people are encouraged to create. And the main way to do 
        that is through offering compensation, giving recognition and allowing an opportunity for 
        community building."/>
      </Section>
      <Section type="pillar" section='FOUR' size='half'>
        <PillarSection title="Supercharge Individual Prosperity" body="Crypto and the decentralized
         web will change many things, beyond even what we have been used to in 
         regards to the way that the world has previously been handled. Internet
          technology has eroded layers off of the traditional way of doing
           things and the third iteration of the web will have the most dramatic
            impact to date. Intermediaries are no longer needed, time and money
             is saved and people's ability to prosper through digital means is higher than 
             ever before." />
      </Section>
      {/* <Section type="cta" section='Be apart of the ntheos alpha' bg="white" size='medium'>
        <TypeformCTA>
          <Typeform />
        </TypeformCTA>
      </Section> */}
      {/* <Section bg='white' section='Tap In With Us' id='carousel' heading='Heading4' size='medium'>

        <CTAStatement />
      </Section> */}
      {/* <Section section='contact' id='cta/about/contact' heading='Heading 5' size='medium'>

      </Section> */}

    </Layout>
  )
};

Pillars.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pillars;

export const query = graphql`

query PillarsPageQuery {
  allPagesJson {
    edges {
      node {
        one
      }
    }
  }
}
`;