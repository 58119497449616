import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Line, Body } from './pillarSection.css';
import { Link } from 'gatsby'


const PillarSection = ({ children, type, body, as = '', title }) => {
    console.log("type: ", type)

    return (
        <>
            <Container >
                <Title >{title}</Title>
                {/* <Line /> */}
                <Body>{body}</Body>
            </Container>
        </>
    );
};

PillarSection.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default PillarSection;