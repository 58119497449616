import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Link } from 'gatsby'
import { blue, orange, grey, } from "../../../constants/theme"

// container
export const Container = styled.div`
// background: red;
height: auto;
width: 100%;
color: black;
${MEDIA.TABLET`

  `};

  ${MEDIA.L_TABLET`

  `};

  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

`

export const Body = styled.p`
top: 0rem;
position: relative;
// background: blue;
padding-top: 0rem;
text-transform: none;
font-size: 1.85rem;
line-height: 3rem;
width: 95%;
color: black;
font-weight: 300;


  ${MEDIA.L_TABLET`

  `};

  ${MEDIA.TABLET`
font-size: 2rem;
line-height: 2.75rem;

  `};
  ${MEDIA.PHONE`
  font-size: 1.5rem;
  line-height: 2.1rem;
  
  `};

  ${MEDIA.M_PHONE`
font-size: 1.33rem;
line-height: 1.8rem;

  `};

  ${MEDIA.S_PHONE`
  font-size: 1rem;
  line-height: 1.45rem;
  
  `};

`

// tag list
export const Line = styled.hr`
// background: blue;
height: 100%;
margin-top: 10rem;
margin-left: 1rem;
width: 100%;
height: 75%;
display: flex;
// align-items: center;
flex-wrap: wrap;
max-width: 1200px;

li {
    left: 0rem;
    margin-top: 0;
    margin-bottom: 0;
    position: static;
    margin-right: 2rem;
}
${MEDIA.L_TABLET`

`};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  li {
   
  }
  `};

  ${MEDIA.M_PHONE`
margin-top: 8rem;
li {
   margin-top: .75rem;
   margin-right: 1.25rem;
}
  `};

  ${MEDIA.S_PHONE`

  `};
`
// tag list
export const Title = styled.h1`
// background: blue;
height: 100%;
margin-top: 10rem;
margin-left: 1rem;
font-family: "Termina";
font-weight: 800;
padding-bottom: 2rem;
font-size: 3rem;
color: ${grey};

${MEDIA.L_TABLET`

`};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  font-size: 2.25rem;

  `};

  ${MEDIA.M_PHONE`
  font-size: 1.8rem;
  margin-left: 0;
  width: 90%;
  padding-bottom: 1rem;
margin-top: 8rem;
li {
   margin-top: .75rem;
   margin-right: 1.25rem;
}
  `};

  ${MEDIA.S_PHONE`
  font-size: 1.5rem;

  `};
`